import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Html5QrcodePlugin from "../components/forms/Scanner";
import { useGeolocated } from "react-geolocated";
import { db } from "../db/db";
import { useLiveQuery } from "dexie-react-hooks";
import { version, multiX } from "../components/otros/dominios";
import { toast } from "react-toastify";
import {
  decompressFromBase64,
  compressToBase64,
  compressToUTF16,
} from "lz-string";
import QRCode from "react-qr-code";
import { getDestinos } from "../functions/patagoniaApi";

import {
  osVersion,
  osName,
  mobileVendor,
  mobileModel,
  browserName,
} from "react-device-detect";

import { decode } from "html-entities";

// Componentes
import Header from "../components/nav/Header";
import BottomMenu from "../components/nav/BottomMenu";

// Textos
import {
  recibir,
  leer_qr,
  documento_en_stock,
  qr_generado,
  entregar,
  recibir_guia_despacho,
  guia_scanned,
  guia_scanned_update,
  seleccionar_stock,
  enviar_guias,
} from "../components/otros/textos";

// Funciones
import { onNewScanResultGuia } from "../functions/handleQr";

function getTimestamp() {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  return date + " " + time;
}

const entregaData = {
  nombreReceptor: "",
  rutReceptor: "",
};

function formatRut(rut) {
  const rutLimpio = rut.replace(/[^0-9kK]/g, "");
  const cuerpo = rutLimpio.slice(0, -1);
  const dv = rutLimpio.slice(-1).toUpperCase();
  if (rutLimpio.length < 2) return rutLimpio;
  let cuerpoFormatoMiles = cuerpo
    .toString()
    .split("")
    .reverse()
    .join("")
    .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
  cuerpoFormatoMiles = cuerpoFormatoMiles
    .split("")
    .reverse()
    .join("")
    .replace(/^[\.]/, "");
  return `${cuerpoFormatoMiles}-${dv}`;
}

const aceptar = {
  aceptarGuia: "Sin reparo",
  obs: "",
};

const Carga = () => {
  const [entrega, setEntrega] = useState(entregaData);

  const [guiaqr, setGuiaqr] = useState([]);
  const [qrEntrega, setQrEntrega] = useState([]);
  const [qrData, setQrData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [aceptarData, setAceptar] = useState(aceptar);
  const [capturedImages, setCapturedImages] = useState(["", "", ""]);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  let { tipoCarga } = useParams();
  let navigate = useNavigate();

  const { nombreReceptor, rutReceptor } = entrega;

  const identidad = useLiveQuery(() => db.identidades.toArray());

  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      // Access the user's current position
      const { latitude, longitude } = coords;
      console.log("Latitude:", latitude);
      console.log("Longitude:", longitude);

      // Perform any necessary actions with the coordinates here
      // For example, you can make an API request to fetch data based on the user's location.
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, coords]);

  useEffect(() => {
    getDestinos()
      .then((res) => {
        console.log(res.data);
        // db.destinos.clear()

        db.destinos.put({ id: 1, unidades: res.data.unidades });
      })
      .catch((err) => console.log(err));
  }, []);

  const { obs } = aceptarData;

  const handleChangeAcept = (e) => {
    setAceptar({ ...aceptarData, [e.target.name]: e.target.value });
  };

  const loadGuia = useRef(false);

  let guiasItems = guiaqr && guiaqr.items;

  guiaqr.obs = aceptarData.obs;
  guiaqr.img1 = capturedImages[0];
  guiaqr.img2 = capturedImages[1];
  guiaqr.img3 = capturedImages[2];

  // Funciones

  // Escaneo de guia
  const escanearGuia = async (e) => {
    onNewScanResultGuia(e, coords & coords)
      .then((res) => {
        setGuiaqr(res.guia);
        navigate(res.ruta);
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
        toast.warning('No es posible leer el QR. Le recomendamos que lo intente nuevamente en un lugar más iluminado.');
      });
  };

  const so = {
    version: osVersion,
    os: osName,
    marca: mobileVendor,
    modelo: mobileModel,
    navegador: browserName,
  };

  // Recibir guia
  const onSubmitGuia = async () => {

    try {

      const location = coords && coords.latitude + "," + coords.longitude;

      let guia = {};

      const date = new Date();

      let day = date
        .getDate()
        .toLocaleString("en-US", { minimumIntegerDigits: 2 });
      let month = (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      });
      let year = date.getFullYear().toString().substr(-2);
      const hour = date
        .getHours()
        .toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const minuto = date
        .getMinutes()
        .toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const segundo = date
        .getSeconds()
        .toLocaleString("en-US", { minimumIntegerDigits: 2 });

      let currentDate = `${year}${month}${day}${hour}${minuto}${segundo}`;

      guia.url = "https://" + multiX.domain + "/sync/notificar/RECIBIR-GUIA-COMPLETA";
      guia.post = "key=" + multiX.key +
        "&numero=" + guiaqr.numero +
        "&rut=" + guiaqr.rut +
        "&content=" + guiaqr.content +
        "&items=" + guiaqr.i +
        "&fecha=" + guiaqr.fecha +
        "&destino=" + guiaqr.destino +
        "&ped=" + guiaqr.pedido +
        "&ent=" + guiaqr.entrega +
        "&timestamp=" + currentDate +
        "&coordenadas=" + location +
        "&tt_id=" + identidad[0].idTransporte +
        "&tt_rut=" + identidad[0].rutPiloto +
        "&obs=" + aceptarData.obs +
        "&img1=" + capturedImages[0] +
        "&img2=" + capturedImages[1] +
        "&img3=" + capturedImages[2] +
        "&ga=" + guiaqr.ga +
        "&v=" + version +
        "&t=1" +
        "&so=" + JSON.stringify(so);

      loadGuia.current = true;

      let dataFetchRecepcion = {};

      dataFetchRecepcion.key = multiX.key;
      dataFetchRecepcion.numero = guiaqr.numero;
      dataFetchRecepcion.rut = guiaqr.rut;
      dataFetchRecepcion.content = guiaqr.content;
      dataFetchRecepcion.items = guiaqr.i;
      dataFetchRecepcion.fecha = guiaqr.fecha;
      dataFetchRecepcion.destino = guiaqr.destino;
      dataFetchRecepcion.ped = guiaqr.pedido;
      dataFetchRecepcion.ent = guiaqr.entrega;
      dataFetchRecepcion.timestamp = currentDate;
      dataFetchRecepcion.coordenadas = location;
      dataFetchRecepcion.tt_id = identidad[0].idTransporte;
      dataFetchRecepcion.tt_rut = identidad[0].rutPiloto;
      dataFetchRecepcion.obs = aceptarData.obs;
      dataFetchRecepcion.img1 = capturedImages[0];
      dataFetchRecepcion.img2 = capturedImages[1];
      dataFetchRecepcion.img3 = capturedImages[2];
      dataFetchRecepcion.ga = guiaqr.ga;
      dataFetchRecepcion.v = version;
      dataFetchRecepcion.t = 1;
      dataFetchRecepcion.so = JSON.stringify(so);

      const urlSearchParamsRecepcion = new URLSearchParams(dataFetchRecepcion);

      db.guiaDespacho.add({
        id: guiaqr.numero,
        guia: guiaqr,
      });

      if (guiaqr.ga === 1) {
        console.log("Consolidados");

        await fetch(guia.url, {
          method: "POST",
          mode: "cors",
          headers: {
            // 'Content-Type': 'application/json'
            "Content-Type": "application/x-www-form-urlencoded",
          },
          crossDomain: true,
          body: guia.post,
        })
          .then((result) => result.json())
          .then((result) => {
            if (result.error === true) {
              console.log('Falló el primer intento de enterega de la notificación');
              db.requestPendientes.put({
                url: guia.url,
                post: guia.post,
                tipoRequest: "RECIBIR",
              });
            } else {
              console.log("Received the desired response:", result);

              //let guias = JSON.parse(result.guias);
              let guias = result.guias;
              console.log(guias);

              guias.map((c) => {
                db.guiaDespacho.delete(guiaqr.numero);

                let guiaData = {};
                let items = [];

                if (c.m_items.length > 0) {
                  for (let i = 0; i < c.m_items.length; i++) {
                    items.push({
                      categoria_id: c.m_items[i].c,
                      cantidad: c.m_items[i].q,
                      material: c.m_items[i].d,
                      precio: c.m_items[i].p,
                    });
                  }
                  guiaData.items = items;
                }

                guiaData.domain = multiX.domain;
                guiaData.key = multiX.key;
                guiaData.numero = c.e_num;
                guiaData.rut = c.e_rut;
                guiaData._id = guiaData.rut + "-" + guiaData.numero;
                guiaData.received_at = getTimestamp();
                guiaData.received_coords = location;
                guiaData.completed = 1;
                guiaData.saved = 0;
                guiaData.delivered = 0;
                guiaData.fecha = c.e_fecha;
                guiaData.img1 = capturedImages[0];
                guiaData.img2 = capturedImages[1];
                guiaData.img3 = capturedImages[2];
                guiaData.obs = aceptarData.obs;
                guiaData.destino = c.m_dest;
                guiaData.i = JSON.stringify(c.m_items);
                guiaData.ga = c.ga;

                db.guiaDespacho.add({
                  id: guiaData.numero,
                  guia: guiaData,
                });
              });
            }
          })
          .catch((err) => {
            console.log(err);
            db.requestPendientes.put({
              url: guia.url,
              post: guia.post,
              tipoRequest: "RECIBIR",
            });
          });

        setGuiaqr("");
        setEncodedImages([]);
        navigate("/");

      } else {

        db.guiaDespacho.add({
          id: guiaqr.numero,
          guia: guiaqr,
        });

        //Enviar guias
        await fetch(guia.url, {
          method: "POST",
          mode: "cors",
          headers: {
            // 'Content-Type': 'application/json'
            "Content-Type": "application/x-www-form-urlencoded",
            //'Access-Control-Allow-Origin': '*',
          },
          crossDomain: true,
          body: urlSearchParamsRecepcion,
        })
          .then((res) => res.json())
          .then(
            (res) => {
              console.log(res);
              if (res.error === true) {
                //Guardar guias en entregas
                db.requestPendientes.put({
                  url: guia.url,
                  post: guia.post,
                  tipoRequest: "RECIBIR",
                });
              } else {
                //Guardar guias en entregas
              }
            },
            (error) => {
              console.log(error);
              db.requestPendientes.put({
                url: guia.url,
                post: guia.post,
                tipoRequest: "RECIBIR",
              });
            }
          );

        setGuiaqr("");
        setEncodedImages([]);
        navigate("/");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const guias = useLiveQuery(() => db.guiaDespacho.toArray());

  const data =
    guias &&
    guias
      // .filter((c) => c.guia.completed === 1)
      .map((c) => {
        let guia = {};

        const url = multiX.domain;
        const key = multiX.key;

        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString().substr(-2);
        const hour = date.getHours();
        const minuto = date.getMinutes();

        let currentDate = `${year}${month}${day}${hour}${minuto}`;

        guia.n = c.guia.numero;
        // guia.k = c.guia.key
        guia.k = key.key;
        guia.r = c.guia.rut;
        guia.f = c.guia.received_at;

        // guia.f = currentDate
        // guia.u = c.guia.domain
        guia.u = multiX.domain;
        guia.i = c.guia.items.map((c) => {
          return {
            c: c.categoria_id,
            q: c.cantidad,
            d: c.material,
            p: c.precio,
          };
        });

        let guiaChar = JSON.stringify(guia).length;

        var plain = JSON.stringify(guia);
        var compressed = compressToUTF16(plain).trim();

        return {
          guia: c.id,
          rut: c.guia.rut,
          destino: c.guia.destino,
          char: guiaChar,
          completed: c.guia.completed,
          charCompress: compressed.length,
        };
      });

  const dataLength =
    guias &&
    guias
      .filter((c) => c.guia.completed === 1)
      .map((c) => {
        let guia = {};

        const url = multiX.domain;
        const key = multiX.key;

        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString().substr(-2);
        const hour = date.getHours();
        const minuto = date.getMinutes();

        let currentDate = `${year}${month}${day}${hour}${minuto}`;

        guia.n = c.guia.numero;
        // guia.k = c.guia.key
        guia.k = key.key;
        guia.r = c.guia.rut;
        guia.f = c.guia.received_at;

        // guia.f = currentDate
        // guia.u = c.guia.domain
        guia.u = url.domain;
        guia.i = c.guia.items.map((c) => {
          return {
            c: c.categoria_id,
            q: c.cantidad,
            d: c.material,
            p: c.precio,
          };
        });

        let guiaChar = JSON.stringify(guia).length;

        var plain = JSON.stringify(guia);
        var compressed = compressToUTF16(plain).trim();

        return {
          guia: c.id,
          rut: c.guia.rut,
          char: guiaChar,
          charCompress: compressed.length,
        };
      });

  const deleteRow = (id) => {
    console.log(id.guia);
    // loadGuia.current = true
    db.guiaDespacho
      .delete(id.guia)
      .then((results) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error); // Error handling
      });
  };

  const unidades = useLiveQuery(() => db.destinos.toArray());

  const filterUnidades = (codigo) =>
    unidades && unidades.length !== 0 ? (
      unidades[0].unidades
        .filter((d) => d.codigo === codigo)
        .map((c) => {
          return c.nombre;
        })
    ) : (
      <>----</>
    );

  const renderDataRows = () =>
    data &&
    data.map((row) => (
      <tr
        key={row.guia}
        className={
          selectedRow.filter((c) => c.guia === row.guia).length > 0
            ? "seleccionado"
            : "noSeleccionado"
        }
        // onClick={() => handleRowClick(row)}
      >
        <td>
          {row.completed === 1 ? (
            <input
              type="checkbox"
              checked={selectedRow.some(
                (selectedRow) => selectedRow.guia === row.guia
              )}
              onChange={() => handleRowClick(row)}
            />
          ) : (
            <>
              <button
                onClick={() => deleteRow(row)}
                value={row.guia}
                className="btn-red"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </>
          )}
        </td>
        {row.completed === 1 ? (
          <td>
            <Link style={{ color: "green" }} to={`/stock/${row.guia}`}>
              {row.guia}
            </Link>
          </td>
        ) : (
          <td style={{ color: "red" }}>{row.guia}</td>
        )}

        <td>
          {filterUnidades(row.destino) &&
          filterUnidades(row.destino).length === 0
            ? "-----"
            : filterUnidades(row.destino)}
        </td>
      </tr>
    ));

  const enviarOrdenes = () => {
    if (selectedRow.length < 1) {
      toast.error("No ha seleccionado guías");
    } else {
      navigate("/carga/enviar-guias");
      console.log(selectedRow);
    }
  };

  const errorEscanear = () => {};

  const generarQr = async () => {

    // GENERA QR DE ENTREGA

    if (entrega.length < 1 || nombreReceptor === "" || rutReceptor === "") {

      toast.error("No ha ingresado datos de receptor");

    } else {

      const location = coords && coords.latitude + "," + coords.longitude;

      // Mapear id guias a un array
      const keys = selectedRow.map((c) => {
        return c.guia;
      });
      setQrEntrega([]);

      const date = new Date();
      let day = date
        .getDate()
        .toLocaleString("en-US", { minimumIntegerDigits: 2 });
      let month = (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      });
      let year = date.getFullYear().toString().substr(-2);
      const hour = date
        .getHours()
        .toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const minuto = date
        .getMinutes()
        .toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const segundo = date
        .getSeconds()
        .toLocaleString("en-US", { minimumIntegerDigits: 2 });

      let currentDate = `${year}${month}${day}${hour}${minuto}${segundo}`;

      // Traer data de guias y agregar array para generar entrega
      let dataFetch = {};

      dataFetch.key = multiX.key;
      dataFetch.numero = currentDate;
      dataFetch.timestamp = currentDate;
      dataFetch.coordenadas = location;
      dataFetch.tt_id = identidad[0].idTransporte;
      dataFetch.tt_rut = identidad[0].rutPiloto;
      dataFetch.rc_rut = rutReceptor;
      dataFetch.rc_nombre = nombreReceptor; 
      dataFetch.v = version;
      dataFetch.obs = aceptarData.obs;
      dataFetch.img1 = capturedImages[0];
      dataFetch.img2 = capturedImages[1];
      dataFetch.img3 = capturedImages[2];

      let url = "https://" + multiX.domain + "/sync/notificar/ENTREGAR-GUIA-COMPLETA";

      const results = await db.guiaDespacho.bulkGet(keys);

      const guias = results.map((c) => ({
        s_ped: c.guia.ped,
        s_ent: c.guia.ent,
        c_ppu: c.guia.ppu,
        c_cod: c.guia.ccod,
        c_rut: c.guia.crut,
        e_rut: c.guia.rut,
        e_fecha: c.guia.received_at,
        e_num: c.guia.numero,
        m_ori: c.guia.mori,
        m_dest: c.guia.destino,
        m_items: c.guia.items.map((item) => ({
          c: item.categoria_id,
          q: item.cantidad,
          d: item.material,
          p: item.precio,
        })),
      }));

      // results.map((c) => {
      //   return db.guiaDespachoEntregadas.add({
      //     id: c.id,
      //     guia: c.guia,
      //   });
      // });

      dataFetch.guias = JSON.stringify(guias);

      console.log(dataFetch);

      const urlSearchParams = new URLSearchParams(dataFetch);

      let post =
        "key=" +
        multiX.key +
        "&numero=" +
        currentDate +
        "&timestamp=" +
        currentDate +
        "&coordenadas=" +
        location +
        "&tt_id=" +
        identidad[0].idTransporte +
        "&tt_rut=" +
        identidad[0].rutPiloto +
        "&obs=" +
        aceptarData.obs +
        "&img1=" +
        capturedImages[0] +
        "&img2=" +
        capturedImages[1] +
        "&img3=" +
        capturedImages[2] +
        "&gaDB=" + 
        "&v=" +
        version +
        "&guias=" +
        JSON.stringify(guias) +
        "&rc_rut=" +
        rutReceptor +
        "&rc_nombre=" +
        nombreReceptor +
        "&so=" +
        JSON.stringify(so);

        //Enviar guias
        await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            // 'Content-Type': 'application/json'
            "Content-Type": "application/x-www-form-urlencoded",
           // 'Access-Control-Allow-Origin': '*',
          },
          crossDomain: true,
          body: urlSearchParams,
        })
          .then((res) => res.json())
          .then(
            (res) => {
              if (res.error === true) {
                db.requestPendientes
                  .add({
                    url: url,
                    post: post,
                    tipoRequest: "ENTREGAR",
                  })
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err));
              }
            },
            (error) => {
              console.log(error);
              db.requestPendientes
                .put({
                  url: url,
                  post: post,
                  tipoRequest: "ENTREGAR",
                })
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
            }
          );

        const domain = multiX.domain;
        const key = multiX.key;

        console.log({ domain, key });

        let guiaQr = {};

        guiaQr.ga = 1;
        guiaQr.s_ped = "000000";
        guiaQr.s_ent = "000000";
        guiaQr.c_ppu = "000000";
        guiaQr.c_cod = "000000";
        guiaQr.c_rut = "000000";
        guiaQr.e_rut = selectedRow[0].rut;
        guiaQr.e_fecha = `${currentDate}`;
        guiaQr.e_num = `${currentDate}`;
        guiaQr.m_ori = "000000";
        guiaQr.m_dest = "000000";
        guiaQr.m_items = [
          { c: "Z20000 ", q: keys.length, d: "GUIAS CONSOLIDADAS", p: "0" },
        ];

        console.log(guiaQr);

        let guiaC = compressToBase64(JSON.stringify(guiaQr));
        let guiaUrl =
          "https://" +
          multiX.domain +
          "/proxy/guia?" +
          "k=" +
          multiX.key +
          "&t=" +
          guiaC +
          "&s=app";

        console.log(guiaUrl);

        setQrEntrega(guiaUrl);
        setQrData(guiaQr.e_num);
      

      // Borrar de dbGuias
      db.guiaDespacho
        .bulkDelete(keys)
        .then((results) => {
          console.log("Guias borradas"); // Array of objects with the specified keys
        })
        .catch((error) => {
          console.log(error); // Error handling
        });

      navigate("/carga/qr-generado");
      setSelectedRow([]);
    }
  };

  const handleChange = (e) => {
    setEntrega({ ...entrega, [e.target.name]: e.target.value });
  };

  const [encodedImages, setEncodedImages] = useState([]);
  // urlFoto, fecha, idusuario, idguia

  const upload = () => {
    if (window.innerWidth < 768) {
      console.log("Telefono");
      setDivVisible2(!isDivVisible2);
      const constraints = { video: { facingMode: "environment" } };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          const video = videoRef.current;

          // Set the video source to the camera stream
          video.srcObject = stream;
          video.play();
        })
        .catch((error) => {
          console.error("Error accessing the camera: ", error);
        });
    } else {
      console.log("PC");
    }
  };

  const handleCapture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    let width = video.videoWidth;
    let height = video.videoHeight;

    const maxWidth = 1024;
    const maxHeight = 1024;

    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageData = canvas.toDataURL("image/png");

    // setCapturedImages([...capturedImages,imageData])

    if (capturedImages[0] === "") {
      setCapturedImages((prevState) => {
        const newArray = [...prevState];
        newArray[0] = imageData;
        return newArray;
      });
    } else if (capturedImages[1] === "") {
      setCapturedImages((prevState) => {
        const newArray = [...prevState];
        newArray[1] = imageData;
        return newArray;
      });
    } else if (capturedImages[2] === "") {
      setCapturedImages((prevState) => {
        const newArray = [...prevState];
        newArray[2] = imageData;
        return newArray;
      });
    } else {
      toast.error("No hay espacio para mas imagenes.");
    }

    setDivVisible2(!isDivVisible2);
  };

  const [isDivVisible, setDivVisible] = useState(false);

  const toggleDiv = () => {
    setDivVisible(!isDivVisible);
  };

  const getDivClass = () => {
    return isDivVisible ? "disp" : "disp2";
  };

  const [isDivVisible2, setDivVisible2] = useState(false);

  const toggleDiv2 = () => {
    setDivVisible2(!isDivVisible2);
  };

  const getDivClass2 = () => {
    return isDivVisible2 ? "disp" : "disp2";
  };

  const [isDivVisible3, setDivVisible3] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const toggleDiv3 = () => {
    setDivVisible3(!isDivVisible3);
  };

  const getDivClass3 = () => {
    return isDivVisible3 ? "disp" : "disp2";
  };

  const handleSelectImage = (e) => {
    console.log(e);
    setSelectedImage(e);
    setDivVisible3(!isDivVisible3);
  };

  const handleChangeItem = (newValue) => {
    const updatedItems = [...capturedImages]; // Create a copy of the array
    updatedItems[selectedImage] = newValue; // Modify the item at the specific index
    setCapturedImages(updatedItems); // Set the modified array back to the state
    setDivVisible3(!isDivVisible3);
  };

  const handleChangeIngresoManual = (event) => {
    const { value } = event.target;
    guiaqr.numero = value;
    console.log(guiaqr.numero);
  };

  const handleChangeSociedad = (event) => {
    const { value } = event.target;
    console.log(value);
    let values = JSON.parse(value);
    guiaqr.rut = values.rut;
    guiaqr.key = values.key;
    guiaqr.domain = values.domain;
    console.log(guiaqr.rut, guiaqr.key);
  };

  const handleRowClick = (row) => {
    const isFound = selectedRow.filter((c) => c.guia === row.guia);

    if (isFound.length > 0) {
      setSelectedRow(selectedRow.filter((id) => id.guia !== row.guia));
    } else {
      const totalSum =
        selectedRow.reduce((sum, item) => sum + item.charCompress, 0) +
        row.charCompress;

      const guiasJSON = dataLength.filter((c) => c.n === row.guia);

      console.log(guiasJSON);
      console.log(totalSum);
      setSelectedRow([
        ...selectedRow,
        {
          guia: row.guia,
          rut: row.rut,
          char: row.char,
          charCompress: row.charCompress,
        },
      ]);
    }

    const rows =
      data &&
      data.map((c) => {
        return c;
      });

    if (Number(selectedRow.length) === Number(rows.length)) {
      // setSelectedRow(rows)
      console.log("Todo");
    }
  };

  const handleRowAllClick = () => {
    const rows =
      data &&
      data.map((c) => {
        return c;
      });

    console.log(selectedRow.length);
    console.log(rows.length);

    if (Number(selectedRow.length) === Number(rows.length)) {
      console.log("=");
      setSelectedRow([]);
    } else {
      console.log(rows);

      setSelectedRow(rows);
    }
  };

  return (
    <>
      <Header />

      {tipoCarga && tipoCarga === "ingreso-manual" && (
        <div className="container p-4">
          <h6>
            <i class="fa-solid fa-truck"></i> Ingreso manual
          </h6>

          <div className="card p-2">
            <label>Ingresar número de guía</label>
            <input
              className="mb-3"
              type="text"
              value={guiaqr.numero}
              onChange={handleChangeIngresoManual}
            />
            <div className="row">
              <div className="col-6">
                <label>Seleccionar sociedad</label>
                <br />
                <select
                  className="w-100"
                  style={{ height: "30px", borderRadius: "3px" }}
                  value={guiaqr.selection}
                  onChange={handleChangeSociedad}
                >
                  <>
                    <option
                      value={JSON.stringify({
                        rut: multiX.rut,
                        key: multiX.key,
                        domain: multiX.domain,
                      })}
                    >
                      {multiX.sociedad}
                    </option>
                  </>
                </select>
              </div>
              <div className="col-6">
                <label>Rut</label>
                <input type="text" value={formatRut(multiX.rut)} disabled />
              </div>
            </div>

            <div className={`camera ${getDivClass2()}`}>
              <div className="foto">
                <video className="video" ref={videoRef} autoPlay playsInline />
                <canvas ref={canvasRef} style={{ display: "none" }} />
              </div>
              <div className="botones mt-3">
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-primary w-100"
                      onClick={handleCapture}
                    >
                      Tomar foto
                    </button>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-secondary w-100"
                      onClick={toggleDiv2}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`camera ${getDivClass3()}`}>
              <div className="foto2">
                <img
                  src={capturedImages[selectedImage]}
                  className="imagenSelected"
                />
              </div>
              <div className="botones mt-3">
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-success w-100"
                      onClick={toggleDiv3}
                    >
                      Conservar
                    </button>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-danger w-100"
                      onClick={() => handleChangeItem("")}
                    >
                      Borrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={getDivClass()}>
              <label>Observaciones</label>
              <br />
              <textarea
                className="w-100"
                name="obs"
                value={obs}
                onChange={handleChangeAcept}
              ></textarea>
              <br />
              <br />
              <buton className="btn-archivo-foto" onClick={upload}>
                Seleccionar archivo
              </buton>
            </div>
            <div className="row">
              <div className="col-12">
                <button className="btn-disp" onClick={toggleDiv}>
                  <i class="fa-regular fa-image"></i>{" "}
                  <i class="fa-solid fa-pencil"></i>
                </button>
              </div>
              <div className="col-12 ">
                <div className="row " style={{ margin: "auto" }}>
                  <div className="col-4 icons">
                    {capturedImages[0] === "" ? (
                      <button className="btn-photo">
                        <i class="fa-brands fa-instagram"></i>
                      </button>
                    ) : (
                      <div
                        onClick={() => handleSelectImage("0")}
                        className="btn-photo"
                        style={{
                          backgroundImage: `url(${capturedImages[0]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="col-4 icons">
                    {capturedImages[1] === "" ? (
                      <button className="btn-photo">
                        <i class="fa-brands fa-instagram"></i>
                      </button>
                    ) : (
                      <div
                        onClick={() => handleSelectImage("1")}
                        className="btn-photo"
                        style={{
                          backgroundImage: `url(${capturedImages[1]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="col-4 icons">
                    {capturedImages[2] === "" ? (
                      <button className="btn-photo">
                        <i class="fa-brands fa-instagram"></i>
                      </button>
                    ) : (
                      <div
                        onClick={() => handleSelectImage("2")}
                        className="btn-photo"
                        style={{
                          backgroundImage: `url(${capturedImages[2]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button
                  onClick={onSubmitGuia}
                  type="button"
                  class="btn-submit w-100 mt-0"
                >
                  {guia_scanned.boton1}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {tipoCarga && tipoCarga === "documento-en-stock" && (
        <div className="container p-4">
          <h6>{documento_en_stock.titulo}</h6>
          <h6 class="text-info-700 mt-3"> {documento_en_stock.titulo2}</h6>
          <p>
            {" "}
            {documento_en_stock.texto1}{" "}
            <strong> {documento_en_stock.texto2}</strong>.
          </p>
        </div>
      )}

      {tipoCarga && tipoCarga === "qr-generado" && (
        <div className="container p-4">
          <h6>
            <i class="fa-solid fa-truck"></i> {qr_generado.titulo}
          </h6>
          <p>
            Con este código QR el siguiente responsable de la carga puede hacer
            su recepción
          </p>
          {qrEntrega.length === 0 ? (
            <></>
          ) : (
            <QRCode
              size={256}
              style={{ height: "300px", maxWidth: "100%", width: "100%" }}
              value={qrEntrega}
              viewBox={`0 0 256 256`}
              level="L"
            />
          )}
          <div className="codigoEntrega mt-4">
            <p className="mt-4">Número de entrega</p>
            <p className="qrEntregaNro"> {qrData}</p>
          </div>

          <div className="row p-0 mt-4">
            {guias && guias.length > 0 ? (
              <>
                <div className="col-6 p-0 text-center">
                  <Link
                    to="/carga/seleccionar-stock"
                    className="btn btn-entrega "
                  >
                    {qr_generado.boton1}{" "}
                  </Link>
                </div>
                <div className="col-6 p-0 text-center">
                  <Link to="/" className="btn btn-recibir">
                    {qr_generado.boton2}{" "}
                  </Link>
                </div>
              </>
            ) : (
              <div className="col-12">
                <Link to="/" className="btn btn-recibir w-100">
                  {qr_generado.boton2}{" "}
                </Link>
              </div>
            )}
          </div>
        </div>
      )}

      {tipoCarga && tipoCarga === "entregar" && (
        <div className="container p-4">
          <h6>
            <i class="fa-solid fa-truck"></i> {entregar.titulo}
          </h6>
          <p>{entregar.subtitulo}</p>

          <Link
            to={"/carga/seleccionar-stock"}
            type="button"
            class="btn w-100 btn-primary mt-3"
          >
            {" "}
            {entregar.boton1} <i class="fa-solid fa-sheet-plastic"></i>
          </Link>
        </div>
      )}

      {tipoCarga && tipoCarga === "recibir-guia-despacho" && (
        <div className="container p-4 mb-5">
          <h6>
            <i class="fa-solid fa-truck"></i> {recibir_guia_despacho.titulo}
          </h6>
          <p>{recibir_guia_despacho.subtitulo}</p>
          <Html5QrcodePlugin
            fps={15}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={escanearGuia}
            qrCodeErrorCallback={errorEscanear}
            verbose={true}
            showTorchButtonIfSupported={true}
          />
        </div>
      )}

      {tipoCarga && tipoCarga === "guia-scanned" && (
        <div className="container p-4">
          <h6>
            <i class="fa-solid fa-truck"></i> {guia_scanned.titulo}
          </h6>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr>
                <td class="p-1">{guia_scanned.item1}</td>
                <td class="p-1">
                  <strong>{guiaqr.numero}</strong>
                </td>
                <td class="p-1">{guia_scanned.item2}</td>
                <td class="p-1">
                  <strong>{guiaqr.rut}</strong>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="card p-2">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th class="text-end p-1">{guia_scanned.columna1}</th>
                  <th class="p-1">{guia_scanned.columna2}</th>
                </tr>
              </thead>
              <tbody>
                {guiasItems &&
                  guiasItems.map((c) => (
                    <tr>
                      <td>{c.cantidad}</td>
                      <td>{decode(c.material)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className={`camera ${getDivClass2()}`}>
              <div className="foto">
                <video className="video" ref={videoRef} autoPlay playsInline />
                <canvas ref={canvasRef} style={{ display: "none" }} />
              </div>
              <div className="botones mt-3">
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-primary w-100"
                      onClick={handleCapture}
                    >
                      Tomar foto
                    </button>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-secondary w-100"
                      onClick={toggleDiv2}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`camera ${getDivClass3()}`}>
              <div className="foto2">
                <img
                  src={capturedImages[selectedImage]}
                  className="imagenSelected"
                />
              </div>
              <div className="botones mt-3">
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-success w-100"
                      onClick={toggleDiv3}
                    >
                      Conservar
                    </button>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-danger w-100"
                      onClick={() => handleChangeItem("")}
                    >
                      Borrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={getDivClass()}>
              <label>Observaciones</label>
              <br />
              <textarea
                className="w-100"
                name="obs"
                value={obs}
                onChange={handleChangeAcept}
              ></textarea>
              <br />
              <br />
              <buton className="btn-archivo-foto" onClick={upload}>
                Seleccionar archivo
              </buton>
            </div>
            <div className="row">
              <div className="col-12">
                <button className="btn-disp" onClick={toggleDiv}>
                  <i class="fa-regular fa-image"></i>{" "}
                  <i class="fa-solid fa-pencil"></i>
                </button>
              </div>
              <div className="col-12 ">
                <div className="row " style={{ margin: "auto" }}>
                  <div className="col-4 icons">
                    {capturedImages[0] === "" ? (
                      <button className="btn-photo">
                        <i class="fa-brands fa-instagram"></i>
                      </button>
                    ) : (
                      <div
                        onClick={() => handleSelectImage("0")}
                        className="btn-photo"
                        style={{
                          backgroundImage: `url(${capturedImages[0]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="col-4 icons">
                    {capturedImages[1] === "" ? (
                      <button className="btn-photo">
                        <i class="fa-brands fa-instagram"></i>
                      </button>
                    ) : (
                      <div
                        onClick={() => handleSelectImage("1")}
                        className="btn-photo"
                        style={{
                          backgroundImage: `url(${capturedImages[1]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="col-4 icons">
                    {capturedImages[2] === "" ? (
                      <button className="btn-photo">
                        <i class="fa-brands fa-instagram"></i>
                      </button>
                    ) : (
                      <div
                        onClick={() => handleSelectImage("2")}
                        className="btn-photo"
                        style={{
                          backgroundImage: `url(${capturedImages[2]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button
                  onClick={onSubmitGuia}
                  type="button"
                  class="btn-submit w-100 mt-0"
                >
                  {guia_scanned.boton1}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {tipoCarga && tipoCarga === "seleccionar-stock" && (
        <div className="container p-4">
          <h6>{seleccionar_stock.titulo}</h6>
          <p style={{ fontSize: "12px" }}>{seleccionar_stock.subtitulo}</p>

          {/* {JSON.stringify(unidades)} */}
          <table className="table-logpro">
            <thead>
              <tr>
                <th>
                  {" "}
                  <input
                    type="checkbox"
                    checked={
                      Number(selectedRow && selectedRow.length) ===
                      Number(data && data.length)
                    }
                    onChange={handleRowAllClick}
                  />
                </th>
                <th>{seleccionar_stock.columna2}</th>
                <th>Destino</th>
              </tr>
            </thead>
            <tbody>{renderDataRows()}</tbody>
          </table>
          {/* {JSON.stringify(selectedRow)} */}
          <button className="btn btn-entrega w-100" onClick={enviarOrdenes}>
            {seleccionar_stock.boton1}{" "}
            {selectedRow.length > 0 ? <>({selectedRow.length})</> : <>(0)</>}
          </button>
        </div>
      )}

      {tipoCarga && tipoCarga === "enviar-guias" && (
        <div className="container p-4" style={{ marginBottom: "80px" }}>
          <h6>{enviar_guias.titulo}</h6>
          <p style={{ fontSize: "12px" }}>{enviar_guias.subtitulo}</p>

          <h6>{enviar_guias.subtitulo2}</h6>

          <div className="card p-2">
            <table className="table-logpro">
              <thead>
                <tr>
                  <th>{enviar_guias.columna1}</th>
                  <th>{enviar_guias.columna2}</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow &&
                  selectedRow.map((row) => (
                    <tr key={row.guia}>
                      <td>{row.rut}</td>
                      <td>{row.guia}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className={`camera ${getDivClass2()}`}>
              <div className="foto">
                <video className="video" ref={videoRef} autoPlay playsInline />
                <canvas ref={canvasRef} style={{ display: "none" }} />
              </div>
              <div className="botones mt-3">
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-primary w-100"
                      onClick={handleCapture}
                    >
                      Tomar foto
                    </button>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-secondary w-100"
                      onClick={toggleDiv2}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`camera ${getDivClass2()}`}>
              <div className="foto">
                <video className="video" ref={videoRef} autoPlay playsInline />
                <canvas ref={canvasRef} style={{ display: "none" }} />
              </div>
              <div className="botones mt-3">
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-primary w-100"
                      onClick={handleCapture}
                    >
                      Tomar foto
                    </button>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-secondary w-100"
                      onClick={toggleDiv2}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`camera ${getDivClass3()}`}>
              <div className="foto2">
                <img
                  src={capturedImages[selectedImage]}
                  className="imagenSelected"
                />
              </div>
              <div className="botones mt-3">
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-success w-100"
                      onClick={toggleDiv3}
                    >
                      Conservar
                    </button>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      className="btn btn-danger w-100"
                      onClick={() => handleChangeItem("")}
                    >
                      Borrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={getDivClass()}>
              <label>Observaciones</label>
              <br />
              <textarea
                className="w-100"
                name="obs"
                value={obs}
                onChange={handleChangeAcept}
              ></textarea>
              <br />
              <br />
              <buton className="btn-archivo-foto" onClick={upload}>
                Seleccionar archivo
              </buton>
            </div>
            <div className="row">
              <div className="col-12">
                <button className="btn-disp" onClick={toggleDiv}>
                  <i class="fa-regular fa-image"></i>{" "}
                  <i class="fa-solid fa-pencil"></i>
                </button>
              </div>
              <div className="col-12 ">
                <div className="row " style={{ margin: "auto" }}>
                  <div className="col-4 icons">
                    {capturedImages[0] === "" ? (
                      <button className="btn-photo">
                        <i class="fa-brands fa-instagram"></i>
                      </button>
                    ) : (
                      <div
                        onClick={() => handleSelectImage("0")}
                        className="btn-photo"
                        style={{
                          backgroundImage: `url(${capturedImages[0]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="col-4 icons">
                    {capturedImages[1] === "" ? (
                      <button className="btn-photo">
                        <i class="fa-brands fa-instagram"></i>
                      </button>
                    ) : (
                      <div
                        onClick={() => handleSelectImage("1")}
                        className="btn-photo"
                        style={{
                          backgroundImage: `url(${capturedImages[1]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="col-4 icons">
                    {capturedImages[2] === "" ? (
                      <button className="btn-photo">
                        <i class="fa-brands fa-instagram"></i>
                      </button>
                    ) : (
                      <div
                        onClick={() => handleSelectImage("2")}
                        className="btn-photo"
                        style={{
                          backgroundImage: `url(${capturedImages[2]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <form className="mt-4">
              <label>{enviar_guias.label1}</label>
              <input
                type="text"
                name="nombreReceptor"
                required
                value={nombreReceptor}
                onChange={handleChange}
              />

              <label className="mt-2">{enviar_guias.label2}</label>
              <input
                type="text"
                name="rutReceptor"
                required
                value={formatRut(rutReceptor)}
                onChange={handleChange}
              />
            </form>

            <button className="btn btn-entrega mt-3 w-100" onClick={generarQr}>
              {enviar_guias.boton1}
            </button>
          </div>
        </div>
      )}

      <BottomMenu />
    </>
  );
};

export default Carga;
